import React, { useCallback, useMemo } from "react";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import router, { updateRoute } from "@/__main__/router.mjs";
import ProMatchesList from "@/game-lol/components/ProMatchesList.jsx";
import RolesButtons from "@/game-lol/components/RolesButtons.jsx";
import { FILTER_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import { getProMatchlistQueryString } from "@/game-lol/utils/probuilds-utils.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import { getDefaultedFiltersForProBuilds } from "@/game-lol/utils/util.mjs";
import ExclamationIcon from "@/inline-assets/exclamation-mark.svg";
import parseSearchParams from "@/util/parse-search-params.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export const PlaceholderContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--sp-22) * 3);

  svg {
    width: 7rem;
    height: 7rem;
  }
`;

const navigateToRoute = (urlParams) => {
  const path = router?.route?.currentPath;
  updateRoute(path, urlParams);
};

const History = () => {
  const state = useSnapshot(readState);
  const { searchParams } = useRoute();
  const defaultFilter = getDefaultedFiltersForProBuilds(searchParams);

  const filter = useMemo(() => {
    return {
      role: defaultFilter.role,
      team: defaultFilter.team,
    };
  }, [defaultFilter]);

  const doFilter = useCallback((filterParams) => {
    if (!filterParams.role) delete filterParams.role;
    if (!filterParams.team) delete filterParams.team;
    const urlParams = parseSearchParams(filterParams);
    navigateToRoute(urlParams);
  }, []);

  const setFilter = useCallback(
    (key, val) => {
      switch (key) {
        case FILTER_SYMBOLS.role:
          doFilter({ ...filter, role: val });
          break;
        case FILTER_SYMBOLS.team:
          doFilter({ ...filter, team: val });
          break;
      }
    },
    [filter, doFilter],
  );

  const {
    lol: { proBuildMatchlist },
  } = state;

  const queryString = getProMatchlistQueryString(null, filter.role);
  const data = proBuildMatchlist[queryString];

  const loading = !data;

  const filters = (
    <div className="flex wrap gap-sp-2">
      <RolesButtons
        withName
        selected={RoleSymbol(filter.role)}
        onClick={(v) => {
          setFilter(FILTER_SYMBOLS.role, v);
        }}
      />
    </div>
  );

  if (loading) {
    return (
      <>
        {filters}
        <Card style={{ display: "grid", gap: "var(--sp-2)" }}>
          {Array.from({ length: 16 }).map((_, index) => (
            <Card
              key={index}
              loading
              style={{ height: 64, background: "var(--shade7)" }}
            />
          ))}
        </Card>
      </>
    );
  } else if (data instanceof Error) {
    return (
      <>
        {filters}
        <Card>
          <PlaceholderContainer>
            <ExclamationIcon />
          </PlaceholderContainer>
        </Card>
      </>
    );
  }

  return (
    <>
      {filters}
      <Card>
        <ProMatchesList matches={data} />
      </Card>
    </>
  );
};

export default History;
